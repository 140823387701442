import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.d,
      stripe: "",
      "empty-text": "没有记录",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "index",
          label: "序号",
          width: "50"
        }),
        _createVNode(_component_el_table_column, {
          prop: "projectName",
          label: "变更事项"
        }),
        _createVNode(_component_el_table_column, {
          prop: "beforeInfoList",
          label: "变更前"
        }, {
          default: _withCtx((scope) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.beforeInfoList, (item, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(index?'、':'') + _toDisplayString(item.content), 1))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "afterInfoList",
          label: "变更后"
        }, {
          default: _withCtx((scope) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.afterInfoList, (item, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(index?'、':'') + _toDisplayString(item.content), 1))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "changeDate",
          label: "变更日期"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.toDate(scope.row.changeDate,'YYYY-MM-DD')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}